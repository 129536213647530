import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
// import { XR, createXRStore } from '@react-three/xr'
import EggIcon from '@mui/icons-material/Egg';
import * as THREE from 'three';
// import gilroyFont from './assets/Gilroy-FREE/Gilroy-ExtraBold.otf';

// import 'aframe';
// import 'ar.js';

import AJBody from './AJBody';
import XROpeningBox from './XROpeningBox';
import TwineStory from './TwineStory';
import { Container } from '@mui/material';
import useSound from 'use-sound';
import popSound from '../assets/pop.mp3';
// import AFRAME from 'aframe';
const AFRAME = window.AFRAME;

 const  cameraDepth = 55
  const scoreCap = { //?-----/ Live /-----//
    score: 6000, //Bubbles x 10
    warn: 100,// # of bubbles
    activationCount: 150, // # of bubbles
    endCount: 160 // # of bubbles
  }
  // const scoreCap = { //!-----/ Test /-----//
  //   score: 400, //Bubbles x 10
  //   warn: 10, // # of bubbles
  //   activationCount: 15 ,// # of bubbles
  //   endCount: 20 // # of bubbles
  // }

// const store = createXRStore()

const clicktagStyles = {
        // position:'absolute',
        // transform:'translate(-50%,150%)',
        display:'flex',
        // top: '18%',
        // left: '50%',
        transform:'translate(-50%,0)',
        height:'2.5rem',
        width:'6.5rem',
        padding:'0.25rem',
        backgroundColor:'rgba(0,0,0,0.7)',
        color: "#fff", 
        backdropFilter:'blur(5px)', 
        fontSize:'0.75rem',
        borderRadius:'15px' ,
        border:'3px solid rgba(0, 0, 0, 1)',
        zIndex: 99999,
        // opacity: blink ? '1' :'0',
        // visibility: blink ? 'visible' :'hidden',
        boxShadow: '0px 0px 10px  rgba(0, 255 ,0, 0.4)',
        // whiteSpace:'nowrap'
        textAlign:'center',
        alignContent:'center',
        alignItems:'center',
        justifyContent: 'center',
        color: '#00FF00',
        gap:'0.25rem'

    }
    AFRAME.registerComponent('light-tube-controller', {
  schema: {
    active: {type: 'boolean', default: false}
  },

  update: function() {
    var lightTube = this.el.querySelector('#glowingTube');
    if (lightTube) {
      lightTube.setAttribute('visible', this.data.active);
    }
  }
});
AFRAME.registerComponent('twine-shader', {
  init: function() {
    try{
      const checkElement = () => {
        if (document.getElementById('twine-story')) {
          this.el.setAttribute('material', 'shader: html; target: #twine-story; width:100%; height:100%');
          // this.el.setAttribute('material', 'shader: html; target: ./assets/RexEntropyGameIntro.html');
        } else {
          setTimeout(checkElement, 100); // Check again in 100ms
        }
      };
      checkElement();
    }catch(err){console.log(err)}

    }
});

AFRAME.registerComponent('show-when-looking-down', {
        schema: {
          threshold: {type: 'number', default: -30}
        },

        init: function() {
          this.camera = document.querySelector('#camera');
          this.text = this.el;
          this.text.setAttribute('visible', false);
        },

        tick: function() {
          if (this.camera) {
            var rotation = this.camera.object3D.rotation;
            var lookingDown = THREE.MathUtils.radToDeg(rotation.x) < this.data.threshold;
            this.text.setAttribute('visible', lookingDown);
          }
        }
      });

AFRAME.registerComponent('show-begin-info', {
      schema: {
        isIntro: {type: 'boolean', default: true}
      },
        init: function() {
          // this.camera = document.querySelector('#begin-info');
          const begin_info = document.createElement('a-plane');
          // begin_info.setAttribute('visible', false);
          
          begin_info.setAttribute('id',"begin-info");
          begin_info.setAttribute('visible',this.data.isIntro);
          begin_info.setAttribute('width','5');
          begin_info.setAttribute('height','5');
          begin_info.setAttribute('material',"src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png; transparent:true; alphaTest:0.5;");
          begin_info.setAttribute('position','0 2 2');

          const begin_text = document.createElement('a-text');
          begin_text.setAttribute('id','begin-text-01')
          begin_text.setAttribute('scale','2 2 2')
          begin_text.setAttribute('position','0 0 0.01')
          begin_text.setAttribute('color','#00FF00')
          begin_text.setAttribute('align','center')
          begin_text.setAttribute('value','You, are life.\n Burst my bubbles.')
          document.querySelector('#scene').appendChild(begin_info)
          begin_info.appendChild(begin_text)
        setTimeout(() => {
            this.el.removeAttribute('show-begin-info');
          }, 5000);
      
        },
        remove: function() {
          const begin_info = document.querySelector('#begin-info');
            if (begin_info) {
              document.querySelector('#scene').removeChild(begin_info);
            }
          }

      });

  AFRAME.registerComponent('link_click', {
     schema: {
    url: {type: 'string'}
  },

  init: function () {
    var data = this.data;
    var el = this.el;

    el.addEventListener('click', function () {
      console.log('[Link Click]');
      window.open(data.url, '_blank');
    });
  }}
)
  AFRAME.registerComponent('ar-activator', {
  init: function() {
    var button = document.querySelector('#ar-cube');
    var scene = this.el;
    
    // Initially disable AR
    scene.setAttribute('arjs', 'sourceType: none;');
    scene.setAttribute('awsd-controls', 'acceleration:100');
    
    button.addEventListener('click', function() {
      // Enable AR and start the camera
      scene.setAttribute('arjs', 'trackingMethod: best; sourceType: webcam;debugUIEnabled: false;');
      scene.setAttribute('XRMode', 'ar');
      scene.setAttribute('ball-manager');
      
      // button.setAttribute('visible', 'false');
    });
  }
});
AFRAME.registerComponent('cursor-listener', {
  init: function () {
    var lastIndex = -1;
    var COLORS = ['red', 'green', 'blue'];
    this.el.addEventListener('click', function (evt) {

      lastIndex = (lastIndex + 1) % COLORS.length;
      this.setAttribute('material', 'color', COLORS[lastIndex]);
      console.log('I was clicked at: ', evt.detail.intersection.point);
    });
  }
});

function deactivateARScene() {
  // Get the scene element
  var scene = document.querySelector('#scene');
  
  // Remove AR.js-related attributes
  scene.setAttribute('arjs','sourceType: none;');
  // scene.removeAttribute('embedded');
  
  // Remove the camera element
  var camera = document.querySelector('[camera]');
  if (camera) {
    camera.parentNode.removeChild(camera);
  }
  
  // Hide all AR content
  var arContent = scene.querySelectorAll('#scene');
  arContent.forEach(function(el) {
    el.setAttribute('visible', false);
  });
  
  // Optional: Display a message or UI indicating AR is deactivated
  // var message = document.createElement('div');
  // message.textContent = 'AR scene deactivated';
  // message.style.position = 'fixed';
  // message.style.top = '50%';
  // message.style.left = '50%';
  // message.style.transform = 'translate(-50%, -50%)';
  // document.body.appendChild(message);
}
AFRAME.registerComponent('scene-deactivator', {
  init: function() {
    this.el.addEventListener('click', function() {
      deactivateARScene();
    });
  }
});

AFRAME.registerComponent('horizon-to-camera', {
  init: function() {
    
    this.animateSphere();
  },
  animateSphere: function() {
    console.log('horizon to camera')
    const sphere = this.el;
    const camera = document.querySelector('#camera');
    const startPosition = new THREE.Vector3(camera.position);
    // const startPosition = new THREE.Vector3(0, 0, -100);
    const endPosition = new THREE.Vector3(0, 1.6, 0);
    let progress = 0;
    const animate = () => {
      
      progress += 0.0005;
      if (progress > 1) return;

      const currentPosition = new THREE.Vector3().lerpVectors(startPosition, endPosition, progress);
      sphere.setAttribute('position', currentPosition);
      sphere.setAttribute('scale', `${progress} ${progress} ${progress}`);

      requestAnimationFrame(animate);
    };
    animate();
  }
});

AFRAME.registerComponent('toggle-pause',{
  schema: {
    isPaused: { type: 'boolean', default: false }
  },
  init: function() {
    var el = this.el;
    var data = this.data;
   
    
    // el.addEventListener('click', function() {
    //   data.isPaused = !data.isPaused;
    //   console.log('[Pause Click] data.isPaused:',data.isPaused)
    //   el.emit('pauseStateChanged', { isPaused: data.isPaused });
    // });
      el.addEventListener('click', () => {
      // Toggle the paused state
      const newState = !this.data.isPaused;
      el.setAttribute('toggle-pause', 'isPaused', newState);
      console.log('[Pause Click] New State:', newState);
    });
  },
  update: function(oldData) {
    if (this.data.isPaused !== oldData.isPaused) {
      console.log('[Pause Click Update]', this.data.isPaused)
     
      this.el.emit('pauseStateChanged', { isPaused: this.data.isPaused });
    }
  }
})
  // schema: {
  //   value: { type: 'boolean' },
  //   onUpdate: { type: 'function' }
  // },
  //  update: function(oldData) {
  //   if (this.data.value !== oldData.value) {
  //     this.data.onUpdate(this.data.value);
  //   }
  // }
  // init: function(){
  //   const pauseToggle = document.querySelector('#toggle-pause')
  //   pauseToggle.addEventListener('click',()=>{
  //     // setIsPaused(!isPaused)
  //   pauseToggle.setAttribute('isPaused',!pauseToggle?.isPaused)
  
  //   })
  // }
// })

AFRAME.registerComponent('change-pause-text',{
  init: function(){
    var el = this.el;
    var pauseTextEl = document.querySelector('#pause-text');
    var isPaused = false;

    el.addEventListener('click', function () {
      // setIsPaused(!isPaused)
       isPaused = !isPaused;
      pauseTextEl.setAttribute('value',isPaused?"Resume?":"Pause?")
  
    })
  
  }
})

AFRAME.registerComponent('ball-manager', {
  schema:{
    isPaused:  {type: 'boolean'}
  },
  init: function() {
    this.score = 0;
    this.generationSpeed = 3000; 
    this.activeBubbles = 0;
    this.totalBubbles = 0;
    this.isActivated = false;
    this.generateBall = this.generateBall.bind(this);
    this.updateScore = this.updateScore.bind(this);
    this.updateSpeed = this.updateSpeed.bind(this);
    this.shakeCamera = this.shakeCamera.bind(this);
    this.updateBubbleCount = this.updateBubbleCount.bind(this);

    // setInterval(this.generateBall, (Math.random() * 3000 + 1000))
    
  setInterval(this.generateBall, this.generationSpeed) 
  },

  generateBall: function() {
    if( this.data.isPaused ){return}
    if( this.totalBubbles >= scoreCap.warn && !this.showWarning ){
      this.showWarning = true;
     // if ( this.showWarning ) {
      console.log('[Pause Click Update]', this.data.isPaused)
      this.el.emit('pauseStateChanged', { isPaused: this.data.isPaused });

      const event = new CustomEvent('showWarning');
      document.dispatchEvent(event);
    //}
    }
    else{
    const ball = document.createElement('a-sphere');
    // const ball = document.createElement('a-entity');
    const startZ = -15;
    const animDur = 10000;
    this.activeBubbles++; 
    this.totalBubbles++; 
    this.updateBubbleCount()
    this.updateGenerationSpeed();
    this.updateSpeed()
    const startPosition = `${Math.random() * 10 - 5} ${Math.random() * 4 - 2} ${startZ + (Math.random() * 3 - 1)}`;
    ball.setAttribute('position', startPosition);
    ball.setAttribute('color','#4CC3D9');
    ball.setAttribute('opacity','0.6');
    ball.setAttribute('gltf-model','#bubble');
    ball.setAttribute('material',`src: ${'#bubble'}`);
    // ball.setAttribute('events',`mousedown: handleBubblePop()`);
    // ball.setAttribute('events',`click: handleBubblePop()`);
    // ball.setAttribute('events',`press: handleBubblePop()`);
    ball.setAttribute('emissive','#4CC3D9');
    ball.setAttribute('emissiveIntensity',0.5);
    ball.setAttribute('alphaTest',0.5);
     ball.setAttribute('metalness',0.5);
    ball.setAttribute('horizon-to-camera');
    ball.setAttribute('scale', '1 1 1');
    ball.setAttribute('class', 'clickable');
    ball.setAttribute('shader', 'phong');
    ball.setAttribute('shadow', 'cast:true; receive:true;');
    ball.setAttribute('raycaster', 'objects: .clickable');

  
    ball.addEventListener('click', () => {
      if(!this.data.isPaused){
        this.updateScore(100);
        this.activeBubbles--; 
        this.updateBubbleCount() 
          const event = new CustomEvent('bubble-pop');
         document.dispatchEvent(event);
      
        ball.parentNode.removeChild(ball);

      }else{return}
    });

    const animation = document.createElement('a-animation');
      // ball.setAttribute('animation__position', `attribute:position; from: 0 1.6 -50; to: 0 1.6 -15; dur:${animDur};easing:'ease-in`);
      // ball.setAttribute('animation__rotation', `attribute: rotation; from: 0 0 0; to: -90 0 0; dur:${animDur};easing:'ease-in; repeat:0`);
      ball.setAttribute('animation__scale', `attribute: scale; to: 3 3 3; dur:${animDur};easing:'ease-in; repeat:0`);
    // animation.setAttribute('attribute', 'position');
    // animation.setAttribute('to', `0 1.6 ${cameraDepth}`);
    // animation.setAttribute('from', '0 1.6 -50');
    // animation.setAttribute('to', '0 1.6 -15');
    // animation.setAttribute('dur', animDur);
    // animation.setAttribute('easing', 'ease-in');

    const rotationAnimation = document.createElement('a-animation');
    // rotationAnimation.setAttribute('begin', 'startAnimation');
      rotationAnimation.setAttribute('attribute', 'rotation');
      rotationAnimation.setAttribute('dur', animDur);
      rotationAnimation.setAttribute('easing', 'ease-in');
      rotationAnimation.setAttribute('from', '0 0 0');
      rotationAnimation.setAttribute('to', '-90 0 0');
      rotationAnimation.setAttribute('repeat', '0');

    const scaleAnimation = document.createElement('a-animation');
    //  scaleAnimation.setAttribute('begin', 'startAnimation');
      scaleAnimation.setAttribute('attribute', 'scale');
      scaleAnimation.setAttribute('from', '0.1 0.1 0.1');
      scaleAnimation.setAttribute('to', '3 3 3');
      scaleAnimation.setAttribute('dur', animDur);
      scaleAnimation.setAttribute('easing', 'ease-in');

    ball.addEventListener('animationend', () => {
      if (ball.parentNode) {
        this.updateScore(-25);
        this.shakeCamera();
         this.activeBubbles--;  
          this.updateBubbleCount()
        ball.parentNode.removeChild(ball);
      }
    });
  
 


  //  ball.appendChild(animation);
  //   ball.appendChild(rotationAnimation);
  //   ball.appendChild(scaleAnimation);
    this.el.sceneEl.appendChild(ball);
    // ball.emit('startAnimation');
  }
},

 
  updateScore: function(points) {
    this.score += points;
    const scoreEl = document.querySelector('#score');
    scoreEl.setAttribute('text', `value: Score: ${this.score}`);
    const charRig = document.querySelector('#character-rig');


    if( this.score >= scoreCap.score && this.totalBubbles >= scoreCap.activationCount ){
          this.isActivated = true;
          
          charRig.setAttribute('light-tube-controller',"active: true")
            // Emit a custom event
          const event = new CustomEvent('ActivationChanged', { detail: { isActivated: true } });
          // const event = new CustomEvent('ActivationChanged');
          document.dispatchEvent(event);
        }
    
  },
  updateBubbleCount: function() {
   
    if (this.totalBubbles >= scoreCap.endCount ){
      if( this.isActivated){
        console.log('[GameOver] - ActivatedConclusion')
        const event = new CustomEvent('ActivatedConclusion');
        document.dispatchEvent(event);
      }else{
         console.log('[GameOver] - BasicConclusion')
         const event = new CustomEvent('BasicConclusion');
         document.dispatchEvent(event);
       }
      const event = new CustomEvent('show-end');
      document.dispatchEvent(event);
       
      } 
   
    const countEl = document.querySelector('#bubble-count');
  if (countEl) {
    countEl.setAttribute('text', `value: Active Bubbles: ${this.activeBubbles} / ${this.totalBubbles}`);
  }
},
  updateSpeed: function() {
  const countEl = document.querySelector('#speed');
  let lvl_val = 'Easier'
  if (countEl) {
    const spd = this.generationSpeed
    switch(true){
      // case (spd >= 2000) :
      //   lvl_val = 'Easier';
      // break;
      case 1000<= spd && spd < 2000 :
        lvl_val = 'Mediumer';
      break;
      case 500<= spd && spd < 1000 :
        lvl_val = 'Harder';
      break;
      case 200<= spd && spd < 500 :
        lvl_val = 'Harderer';
      break;
      case spd <= 200:
        lvl_val = 'Hardererest';
      break;

    }
    
    countEl.setAttribute('text', `value: Rate: ${lvl_val}  |  ${(this.generationSpeed *.001).toFixed(2)}`);

  }
},
resetInterval: function() {
  if (this.intervalId) {
    clearInterval(this.intervalId);
  }
  this.intervalId = setInterval(this.generateBall, this.generationSpeed);
},
 updateGenerationSpeed: function() {
  if (this.totalBubbles % 3 === 0 && this.totalBubbles > 0) {
    this.generationSpeed = Math.max(100, this.generationSpeed - 100);
    this.resetInterval();
  }
},

  shakeCamera: function() {
    const camera = document.querySelector('#camera');
    camera.setAttribute('animation__shake', {
      property: 'position',
      dur: 100,
      dir: 'alternate',
      easing: 'easeInOutSine',
      loop: 4,
      from: '0 1.6 0',
      to: '0.1 1.65 0'
    });
  }
});


const XREnv = (props) => {
  const {setView,view}= props
  const face1 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731962627/Portfolio/Self-Portrait-Plain-NoGlasses_oqjs1i.png'
  const face2 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731962628/Portfolio/Self-Portrait-Plain-NoGlasses-Closed_pkjpyh.png'
  const armsAndLegs ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1732080324/ART/Me_ARMS_LEGS_01_gsjdpo.png'
 //  const gloaun1 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1715795043/PARALLAXR_PRINTFILES/GLOAUN_BASE_BL_01_hvs651.png'
  const gloaun1 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731970728/GLOAUN_001_BL-01-SML_otxefk.png'
  const gloaun2 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731963838/ART/GLOAUN_Blink_yfac78.png'
  const shirts =['https://res.cloudinary.com/dzxzdsnha/image/upload/v1715089642/Shirt-Gridwalker-Basic-Front-_Fit_usjxel.png',
  'https://res.cloudinary.com/dzxzdsnha/image/upload/v1715090957/Shirt-GloAun-Basic-Front-_Fit_rno7jl.png',
  'https://res.cloudinary.com/dzxzdsnha/image/upload/v1715091820/Shirt-Sage-Basic-Front-Fit_fpd9jl.png']
   // const shirt4 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1715090957/Shirt-GloAun-Basic-Front-_Fit_rno7jl.png'
  
  const [red, setRed] = useState(false)
   const [ isAR, setIsAR ] = useState(false)
   const [ isVoid, setIsVoid ] = useState(false)
   const [ isSession, setIsSession ] = useState(false)
   const [ isGameStart , setIsGameStart ] = useState(false)
   const [ isGameEnd , setIsGameEnd ] = useState(false)
   const [ isPaused, setIsPaused ] = useState(false)
   const [ nonCompat, setNonCompat ] = useState(false)
   const [ shirtNum, setShirtNum ] = useState(0)
   const [ isActivated, setIsActivated ] = useState(false)
    const [ showTwineStory, setShowTwineStory ] = useState(false);
    const [ showWarning, setShowWarning ] = useState(false);
    const [ frameRef , setFrameRef ] = useState(null);
    const [ currentPassage, setCurrentPassage ] = useState(showWarning?`SystemWarning`:'Intro');
    const [playPop] = useSound(popSound, { volume: 0.3 });

    const nametag ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731977669/Hello-My-Name-Is-AJ_Bold_nnltsk.png'
   
     const cubeRef = useRef(null);
     const groundRef = useRef(null);
     const skyRef = useRef(null);
     const pauseTextRef = useRef(null);

    const handleStoryComplete = () => {
      setShowTwineStory(false);
      setIsPaused(true);
      setIsGameEnd(true)
    };

      const handleBubblePop = (event) => {
  playPop();
  console.log('-----[Bubble pop sound]-----')
  // Add any other bubble pop logic here, such as removing the bubble
};

  
       const handleCubeClick = () => {
        try{
          //  setIsAR(!isAR)
            // setIsVoid(!isVoid)
            // setIsVoid(!isVoid)
            setShowTwineStory(true);
            if(!isSession){
              setIsAR(true)
              setIsVoid(true)
              handleAROn()
             
            }
            else if(isSession){
              setIsAR(false)
              setIsVoid(false)
              handleAROff()
              console.log('[Cube Click off]:',isAR)
          }
          // if ('xr' in navigator) {
          //   navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
          //     console.log("supported",supported)
          //     if (supported) {
          //         // if(isAR)
          //       setIsVoid(true)
          //       setIsAR(true)
          //       console.log('Entity clicked! [XR] isAR:',isAR); 
          //       console.log('Entity clicked! [XR] isVoid:',isVoid); 
          //     }else{
          //       setIsAR(false)
          //       setIsVoid(true)
          //       console.log('Entity clicked! [NO XR] isAR:',isAR); 
          //       console.log('Entity clicked! [NO XR] isVoid:',isVoid); 
          //     }

          //   }).catch((error) => {
          //   console.error("Error checking AR support:", error);
          //   // handleNoARSupport();
          // });
          // }else{
          //   setIsAR(!isAR)
          //   // setIsVoid(!isVoid)
          //   setIsVoid(!isVoid)
          //   console.log('Entity clicked! [NOT XR]  isAR:',isAR); 
          //   console.log('Entity clicked! [NOT XR] isVoid:',isVoid); 
          // }
          
          // setIsVoid(!isVoid)
        }catch(err){console.log(err)}
        };
      

    const handlePauseUpdate = (newValue) => {

      setIsPaused(newValue)
    }

    const handleVoidOff =() =>{
      
      setIsVoid(false)
    }
    const handleVoidON =() =>{

      setIsVoid(true)
    }
    const handleAROff =() =>{
      // setIsAR(false)
       try{
         if(isSession){
           endARSession() 
           console.log('[Session Ended]')
          }else{
            return
          }
        }catch(err){
          console.log(err)
        }
        setIsAR(false)
        setIsVoid(false)
      
      
    }
    const handleAROn =() =>{
      try{
          setIsAR(true)
          setIsVoid(true)
        if(!isSession && isAR){
          startARSession() 
        }else{
          return
        }
      }catch(err){
        console.log(err)
    }
  }
  const startARSession =()=> {
      navigator.xr.requestSession('immersive-ar')
        .then((session) => {
          // Successfully started an AR session
          setIsSession(true)
          onSessionStarted(session);
        })
        .catch((error) => {
          console.error("Failed to start AR session:", error);
          // handleNoARSupport();
          setNonCompat(true)
          setIsSession(false)
        });
      }

     
    function onSessionStarted(session) {
        // Setup your XR session here
        
      console.log("AR session started");
      const scene = document.querySelector('#scene');
        if (scene) {
          // Remove AR-specific components
          scene.setAttribute('vr-mode-ui',"enabled: false;");
          scene.setAttribute('arjs',`${isAR?"trackingMethod: best; sourceType: webcam; debugUIEnabled: false;":''}`);
          // scene.removeAttribute('embedded');
          
          // Hide AR entities
          const arEntities = scene.querySelectorAll('#camera');
          arEntities.forEach(entity => {
            entity.setAttribute('visible', false);
          });
        }
    }

const endARSession = () =>{
  //  const scene = document.querySelector('#scene');
  // if (scene) {
  //   // Remove AR-specific components
  //   scene.removeAttribute('vr-mode-ui');
  //   scene.removeAttribute('arjs');
  //   // scene.removeAttribute('embedded');
    
  //   // Hide AR entities
  //   const arEntities = scene.querySelectorAll('#camera');
  //   arEntities.forEach(entity => {
  //     entity.setAttribute('visible', false);
  //   });
  // }
  
  // // Remove camera element
  // const camera = document.querySelector('#XREnv');
  // if (camera) {
  //   camera.parentNode.removeChild(camera);
  // }
}
    useEffect(() => {
      if (cubeRef.current) {
        //  setIsAR(!isAR)
            
        //     setIsVoid(!isVoid)
        console.log('Entity clicked! [XR] isAR:',isAR); 
        console.log('Entity click [XR] isVoid:',isVoid)
        cubeRef.current.addEventListener('click', handleCubeClick);

        return () => {
          // if(cubeRef.current){
          //   setIsVoid(!isVoid)
          // }
          // if(cubeRef.current){
          //   cubeRef?.current?.removeEventListener('click', handleCubeClick);
          // }
        };
      }
    }, []);


useEffect(() => {
     const handleBubblePop = (event) => {
        playPop();
        // Add any other bubble pop logic here, such as removing the bubble
      };

      document.addEventListener('bubble-pop', handleBubblePop);
      
      return () => {
        document.removeEventListener('bubble-pop', handleBubblePop);
      };
    }, [currentPassage]);



     useEffect(() => {
      const handleActivationChange = (event) => {
        setIsActivated(event.detail.isActivated);
        setCurrentPassage('ActivatedConclusion')
      };
      const handleActivationConclusion = (event) => {
         setCurrentPassage('ActivatedConclusion')
        // setShowTwineStory(true)
      };
      const handleBasicConclusion = (event) => {
         setCurrentPassage('BasicConclusion')
        // setShowTwineStory(true)
      };
      const handleShowEnd = (event) => {
        setShowTwineStory(true)
      };

      document.addEventListener('ActivationChanged', handleActivationChange);
      document.addEventListener('ActivatedConclusion', handleActivationConclusion);
      document.addEventListener('BasicConclusion', handleBasicConclusion);
      document.addEventListener('show-end', handleShowEnd);

      return () => {
        document.removeEventListener('ActivationChanged', handleActivationChange);
      };
    }, [currentPassage]);

     useEffect(() => {
      const handleWarningToggle = (event) => {
        setCurrentPassage('SystemWarning')
        setShowTwineStory(true);
        setShowWarning(true)
      };

      document.addEventListener('showWarning', handleWarningToggle);

      return () => {
        document.removeEventListener('showWarning', handleWarningToggle);
      };
    }, []);

    
    const sleep =(time)=>{
          return new Promise(resolve => {
              setTimeout(resolve, time)
              // setAnimationFrame instread for event loop purposes
          })  
      }
  function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    
    useEffect(()=>{
      // const changeShirt = () => {
        if( isActivated && shirtNum === 1 ){
          return
        }else{

          sleep(1000)
          const intervalId = setInterval(() => {
                 setShirtNum((shirtNum + 1) % shirts?.length) 
          }, 2000); 
  
          return () => {
              
              clearInterval(intervalId);
          };
        }
      // }

      // changeShirt()
    },[shirtNum])


     useEffect(() => {
          
    }, [isAR,isVoid,isGameStart,showWarning,currentPassage,isGameEnd]);
    
     useEffect(() => {
      // if(pauseTextRef.value === 'Pause'){

      //   setIsPaused(true)
      // }else{
      //   setIsPaused(false)
      // }
    }, [isPaused]);


     useEffect(() => {
      document.addEventListener('load',()=>{

        const pauseEl = document.querySelector('#toggle-pause');
        pauseEl.addEventListener('pauseStateChanged', (e) => {
          setIsPaused(e.detail.isPaused);

        });

      return () => {
        pauseEl.removeEventListener('pauseStateChanged');
      };
      })
  }, []);
    

  return <Container sx={{position:'relative'}}>
    {/* <button onClick={() => store.enterAR()}>Enter AR</button>
    <Canvas>
      <XR store={store}>
        <mesh pointerEventsType={{ deny: 'grab' }} onClick={() => setRed(!red)} position={[0, 1, -1]}>
          <boxGeometry />
          <meshBasicMaterial color={red ? 'red' : 'blue'} />
        </mesh>
      </XR>
    </Canvas> */}
     {/* <div class="arjs-loader" style={{position:'absolute',color:'#00FF00'}}>
  <div>Loading, please wait...</div>
</div> */}

 {/* <TwineStory isVisible={true} onStoryComplete={handleStoryComplete} /> */}
 
   
     
 
     
  
     {view ==='xr' && <a-scene style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
    // dom-overlay="element: #overlay"
    //  embedded 
    // ball-manager={(isAR && !isPaused )?'': null}
    ball-manager={`isPaused: ${(!isAR || isPaused || showTwineStory || isGameEnd)}`}
    show-begin-info={isAR  && (!showTwineStory && !isGameEnd)? `isIntro:${!showWarning}`: null}
    
      id='scene'
      look-controls-enabled
      awsd-controls-enabled//="acceleration:100"
      //  xr-mode-ui={`enabled: ${isAR?'true':'false'}; XRMode: ${isAR?'ar':''};`}
      fly='true'
      // vr-mode-ui="enabled: false;"
      // arjs={isAR?"trackingMethod: best; sourceType: none; debugUIEnabled: false;":' sourceType: none;'}
      // arjs={isAR?"trackingMethod: best; sourceType: webcam; debugUIEnabled: false;":'sourceType: none;'}
      renderer="logarithmicDepthBuffer: true;"
      
      //fog="type: linear; color: #AAA"
      //  xr-mode-ui="enabled: true"
      //  xr-mode-ui={`enabled:  ${isAR?'true':'false'}; enterARButton: #cube; XRMode: ${isAR?'ar':'ar'};`}
      XRMode={isAR?'ar':'vr'}
      ar-activator
      
      >
       <a-assets>
          <img id="groundTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg"/>
          {/* <img id="groundTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731974094/ART/TestGrid_q5jtig.png"/> */}
          {/* <img id="skyTexture" src="https://cdn.aframe.io/a-painter/images/sky.jpg"/> */}
          <img id="grid" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg" crossOrigin="anonymous"></img>
          <img id="porthole" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1732056749/ART/Porthole_Plain_kjw7mw.png"/>
          <img id="skyTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1690040515/Rex_Entropy_Hero_Star_Bg_SML_btvj6p.png"/>
          {/* <img id="skyTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1690071590/Origins_Expanded_Resize_Lo_mpoxfh.jpg"/> */}
          <img id="rexLogo"crossOrigin="anonymous"  src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731961690/Rex_Entropy_Logo_Glow_rtguvp.png"/>
          <img id="rexIcon"crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1686810148/Rex_Entropy_Icon_Glow_hzsixx.png"/>
        <a-asset-item id="customFont" src={"/assets/Gilroy-FREE/Gilroy-ExtraBold.otf"}></a-asset-item>
        <a-asset-item id="bubble" src={"/assets/Bubble_03_Blue.gltf"}></a-asset-item>
        </a-assets>

        {/* <a-entity id="rig"  position="0 0 0 " > */}

        <a-camera 
        position={`0 6 ${cameraDepth}`}  look-controls
        // look-controls-enabled
        wasd-controls-enabled="acceleration:100"
      // wasd-controls
        id='camera'
          animation__rotation="property: rotation; from: 3 ; to: 0; loop:false; dur:1500; easing: linear;
          "
          animation__position="property: position; to: 0 5 20; loop: false; dur: 1500; easing: linear"
        //  gps-camera rotation-reader
         >

          {/* <a-entity position='-5 0 0'>
            <div style={{
    // border:'2px solid red', 
         display: showTwineStory ? 'block' : 'none', 
        //  position: 'absolute', 
        //  backgroundColor:'#fff',
        //  backgroundImage:`url("https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png")`,
        //  backgroundAttachment:'cover',
        //  backgroundPosition:'center',
        //  backgroundAttachment: 'fixed',
        //  backgroundRepeat: 'no-repeat',
        //  backgroundSize: '350px 600px',
         top: '5%', //transform:'translate(0%,0%)', 
         minWidth:'350px', minHeight:'500px', 
         width:'350px', height: '700px', 
         overflow:'visible',
         borderRadius:'8px',
         maxWidth:'600px', maxHeight:'800px', 
         zIndex: 999999999 }}>
     <TwineStory id='twine-story' isVisible={showTwineStory} onStoryComplete={handleStoryComplete} />
  </div>
          </a-entity> */}
         <a-entity 
         cursor="fuse: false; fuseTimeout: 1500" 
         position="0 0 -15"
        //  opacity="0.5"
        //  cursor-listener
         geometry="primitive: ring; radiusInner: 0.4; radiusOuter: 0.5" 
         material="color: #00FF00; shader: phong; opacity: 0.8"
       animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
          // animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
          animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
         //  raycaster="showLine: true; far: 20; interval: 1000;  opacity: 0.5"
          ></a-entity>
           <a-animation attribute="position"
            dur="2000"
            easing="linear"
            to="0 5 15"></a-animation>
        </a-camera>
        {/* </a-entity> */}

        {/* <a-light type="ambient" intensity="3" color="rgba(0, 255, 0, 0.25)"/>
        <a-light type="ambient" color="#445451"/>
        <a-light type="ambient" intensity="1" position="-3 0 1"/> */}

    {/* <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731974094/ART/TestGrid_q5jtig.png" 
     rotation="-90 0 0" height="100" width="100" position="0 0 0"/> */}
     {/* <a-plane src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg" position="0 0 0"
     rotation="-90 0 0" height="5" width="5"/> */}

    {(!isVoid || !isAR)  && <a-plane
      id="ground"
      ref={groundRef}
      // src="#groundTexture" 
      height="200" width="200" position='0 -3 0'
      animation="property: rotation; to: -90 -360 0; loop: true; dur: 18000; easing: linear"
      rotation="-90 0 0" 
      opacity="1"
      //  metalness='0.6' roughness=' 0.4'
      
      material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732047705/ART/grid-png-43557_xldika.png; transparent:true; alphaTest:0.5;" 
      shadow="cast:true; receive:true"
      // material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg; transparent:true; alphaTest:0.5;" shadow="cast:true; receive:true"

     />}
    {(!isVoid || !isAR)  && <a-plane
      id="void-ground"
      // ref={groundRef}
      // src="#groundTexture" 
      height="800" width="800" position='0 -3.1 0'
      // animation="property: rotation; to: -90 -360 0; loop: true; dur: 18000; easing: linear"
      rotation="-90 0 0" 
      opacity="1"
      //  metalness='0.6' roughness=' 0.4'
      
      material="color:#FFF;  alphaTest:0.5;" 
      shadow="cast:true; receive:true"
      // material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg; transparent:true; alphaTest:0.5;" shadow="cast:true; receive:true"

     />}


     <a-entity  position={isAR?"-4.5 6 -22":"-4.5 6 -4"}
>
  <a-plane
    // geometry="primitive: plane; width: 5; height: 1.5; borderRadius: 0.5; opacity:0.5"
    id="arMessage"
    //  color="#000"
     width='5'
     height='3'
     rotation='0 20 0'
    material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png; transparent:true; alphaTest:0.5;"
  //  text="value:You tryin' to Augment Reality?; color:#00FF00; align:center;
  //  scale: 1.5 1.5 1.5"
 />
    
      <a-text
        value="Back to"
        align="center"
        color="#00FF00"
     scale="2 2 2"
        position="0 0.5 0.01"
         rotation='0 20 0'
      ></a-text>
  
      <a-text
        value="Squaresville?"
        align="center"
        color="#00FF00"
        scale="2 2 2"
        position="0 0 0.01"
         rotation='0 20 0'
      ></a-text>

      <a-text
        value="|"
        align="center"
        color="#00FF00"
        scale="5 2 2"
        position="1.4 0 0.01"
         rotation='0 20 0'
         opacity='0'
          animation="property: opacity; to: 1; dur: 1000; easing:linear;loop: true;"
      ></a-text>
      
      <a-image
        src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1732215202/ART/Arrow_Broken_GRN_jq5xik.png'
        align="center"
        color="#00FF00"
        scale="0.6 0.6 0.6"
        position="0 -0.6 0.1"
         rotation='0 20 0'
      ></a-image>
  
</a-entity>

{(!isAR && !isVoid)
&&  <a-entity  position={isAR?"4.5 6 -22":"4.5 6 -4"}
>
  <a-plane
    // geometry="primitive: plane; width: 5; height: 1.5; borderRadius: 0.5; opacity:0.5"
    id="arMessage"
    //  color="#000"
     width='5'
     height='3'
     rotation='0 -20 0'
    material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png; transparent:true; alphaTest:0.5;"
  //  text="value:You tryin' to Augment Reality?; color:#00FF00; align:center;
  //  scale: 1.5 1.5 1.5"
 />
    
      <a-text
        value="You tryin' to"
        align="center"
        color="#00FF00"
     scale="2 2 2"
        position="0 0.25 0.01"
         rotation='0 -20 0'
      ></a-text>
  
      <a-text
        value="Augment Reality?"
        align="center"
        color="#00FF00"
        scale="2 2 2"
        position="0 -0.25 0.01"
         rotation='0 -20 0'
      ></a-text>
      <a-text
        value="|"
        align="center"
        color="#00FF00"
        scale="5 2 2"
        position="1.55 -0.25 1"
         rotation='0 -20 0'
         opacity='0'
          animation="property: opacity; to: 1; dur: 500; easing:linear;loop: true;"
      ></a-text>
  
</a-entity>}

{(isAR && isVoid)
&&  <a-entity  position={isAR?"4.5 6 -22":"4.5 6 -4"}
>
  <a-plane
    // geometry="primitive: plane; width: 5; height: 1.5; borderRadius: 0.5; opacity:0.5"
    id="toggle-pause"
    //  color="#000"
    // toggle-pause={{
    //   value: isPaused,
    //   onUpdate: handlePauseUpdate
    // }}
    toggle-pause={`isPaused: ${isPaused}`}
    onClick={()=>{
      console.log('[Pause onChange]')
      setIsPaused(!isPaused)
    }}
     width='5'
     height='3'
     rotation='0 -20 0'
     class='clickable'
    material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png; transparent:true; alphaTest:0.5;"
  //  text="value:You tryin' to Augment Reality?; color:#00FF00; align:center;
  //  scale: 1.5 1.5 1.5"
 />
    
      <a-text
        ref={pauseTextRef}
        id='pause-text'
        change-pause-text
        isPaused={isPaused}
        value={isPaused?"Resume?":"Pause?"}
        align="center"
        color="#00FF00"
        scale="3 3 3"
        position="0 0 0.01"
         rotation='0 -20 0'
      ></a-text>
  
     
      <a-text
        value="|"
        align="center"
        color="#00FF00"
        scale="5 2 2"
        position="1.55 0.25 0.01"
         rotation='0 -20 0'
         opacity='0'
          animation="property: opacity; to: 1; dur: 500; easing:linear;loop: true;"
      ></a-text>
  
</a-entity>}

      <a-box scene-deactivator
       id='homeCube' ref={cubeRef} position={isAR?"-4 4 -23":"-4 4 -3"} rotation="0 45 0" color="#4CC3D9"
       opacity='0.7'
       //  event-set__click="_event: click;_target:#cube; color: #8FF7FF"
       //  event-set__down="_event: mousedown;_target:#cube; color: #8FF7FF"
       //  event-set__up="_event: mouseup;_target:#cube; color: #4CC3D9"
       animation="property: rotation; to: 0 0 360; loop: true; dur: 2000; easing: linear"
      //  animation__scale="property: scale; dir: alternate; dur: 100; loop: true; to: 1.1 1.1 1.1"
       // animation={{property: 'rotation', dur: 2000, loop: true, to: '360 360 360'}}
      onClick={()=>{
        // setView('home')
               setIsAR(false)  
              //  window.location.assign('/')
               window.close()
              }}
              onPress={()=>{
                // setView('home')
                setIsAR(false)  
                window.close()
              
              // window.location.assign('/')
          }}
      >
       
       </a-box>

           
      <a-entity id='ar-cube' ref={cubeRef} position={isAR?"4 4 -22":"4 2 0"} rotation="0 0 0"
      emissive='#FFF'
      metalness={1}
      emissiveIntensity={1}
      //  animation="property: rotation; to: 360 360 360; loop: true; dur: 2000; easing: linear"
       animation__scale="property: scale; dir: alternate; dur: 100; loop: true; to: 1.1 1.1 1.1"
      >
         
        <XROpeningBox isAR={isAR} />
      </a-entity>

    

     {(!isVoid  || !isAR) && <a-sky
       ref={skyRef}
        id='sky'
         src={'#skyTexture'} 
          //  height="2048" 
          radius="50" theta-length="90"
            // width="2048" 
          animation="property: rotation; to: 0 360 0; loop: true; dur: 18000; easing: linear"
          />}
{/* <a-entity
         geometry="primitive: plane; width: 10000; height: 10000;" rotation="90 0 0"
        material="src: #grid; transparent: true;metalness:0.6; roughness: 0.4;"></a-entity> */}

      {/* <a-box position="-1 0.5 -3" rotation="0 45 0" color="blue"></a-box>
      <a-sphere position="0 1.25 -5" radius="1.25" color="red"></a-sphere>
      <a-cylinder position="1 0.75 -3" radius="0.5" height="1.5" color="green"></a-cylinder>
      <a-plane position="0 0 -4" rotation="-90 0 0" width="4" height="4" color="gray"></a-plane> */}
      {/* Rex_Entropy_Logo_Glow.png */}
     {/* <a-sky src={'./Rex_Entropy_Hero_Star_Bg.png'}height="2048" radius="30" theta-length="90" width="2048" /> */}
    
      <a-entity position={isAR ?'0 0 -25': '0 0 0' }  >

      <a-plane
      link_click='url: https://rexentropy.com'
       material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731961690/Rex_Entropy_Logo_Glow_rtguvp.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="6" width="12" position="0 17 -6" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082371/ART/Sun_01_aogss2.png; transparent:true; color:#FFF;alphaTest: 0.5 "
       shadow="cast:true; receive:true"
      height="6" width="6" position="-2 21 -7" opacity='0.4' 
      rotation='75 0 0' 
      animation__opacity="property: opacity;to: 0.7; loop: false; dir: alternate; dur: 15000; easing: linear"
      // animation__position="property: position; to: -2 20 -7; dir: alternate; loop: true; dur: 15000; easing: linear"
      />

      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="10" position="3 20 -7.5" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="15" position="-3 20 -7" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="10" position="-5 15 -5" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="12" position="6 15 -5" rotation="0 -40 0"/>
        {/* <a-plane material={`src:${'https://res.cloudinary.com/dzxzdsnha/image/upload/v1686810148/Rex_Entropy_Icon_Glow_hzsixx.png'}; transparent:true; color:#FFF`}// shadow="cast:true; receive:true"
        height="4" width="4" position="0 6.5 -7"  rotation="0 0 -50"/> */}

        <a-plane 
          id="qaura-icon"
          link_click="url: https://qaura.pro"
          onClick={()=>{
              setIsPaused(true)
            }}
          onPress={()=>{
            setIsPaused(true)
            }}
          material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732143363/ART/QauRa_Logo_GRN_quodlo.png;  transparent:true; color#FFF; alphaTest: 0.5; side: double" 
          // shadow="cast:true; receive:true"

          class='clickable'
          height="1.5"
          width="4"
          position="-4 11.5 -4"
          opacity="1"
          animation__opacity="property: opacity;to: 0.5; loop: true; dir: alternate; dur: 1500; easing: linear"
          
          />
        
       
        <a-plane
        link_click="url: https://parallaxr.shop"
         material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731981953/Parallaxr_Flat_Tag_Green_bxws9j.svg; transparent:true; color#FFF; alphaTest: 0.5; side: double" shadow="cast:true; receive:true"
        height="2" width="2" position="0 11.25 -3"
        opacity="1"
        animation__opacity="property: opacity;to: 0.5; loop: true; dir: alternate; dur: 1500; easing: linear"
           onClick={()=>{
              setIsPaused(true)
            }}
          onPress={()=>{
            setIsPaused(true)
            }}
         />


        <a-plane 
         link_click="url: https://music.rexentropy.com"
        material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732155657/ART/RexPlayer_Logo_GRN_gdkto8.png; transparent:true; color#FFF; alphaTest: 0.5; side: double" 
        // shadow="cast:true; receive:true"
          height="2"
          width="4"
           position="4 11.5 -4"
           opacity="1"
           animation__opacity="property: opacity;to: 0.5; loop: true; dir: alternate; dur: 1500; easing: linear"
            onClick={()=>{
              setIsPaused(true)
            }}
          onPress={()=>{
            setIsPaused(true)
            }}
          />

      </a-entity>
      
      <a-text value="Look up," 
          position="0 -0.5 10" 
          rotation="-45 0 0"
          scale="3 3 3"
          color="red" 
          align="center" 
          show-when-looking-down="threshold: -40">
      </a-text>
      <a-text value="life's beautiful." 
          position="0 -1.5 10" 
          scale="3 3 3"
          rotation="-45 0 0"
          color="red" 
          align="center" 
          show-when-looking-down="threshold: -40">
      </a-text>
   
{/* 
      ----------------------
     -------- Body  ----------
      ----------------------
 */}

        <a-entity position={!isAR ?'0 0 0': '0 0 0' } 
        id="character-rig"
        animation={isAR ?"property: position; to: 0 -2.5 -20; loop: false; dur: 500; easing: linear":''}
        //  light-tube-controller="active: true"
         >
          <AJBody setView={setView} isActivated={isActivated} setIsActivated={setIsActivated}/>
          <a-entity id="glowingTube" visible="false">

            <a-cylinder 
              id="lightTube" 
            animation__opacity="property: opacity;to: 0.5; loop: true; dir: alternate; dur: 1500; easing: linear"
              radius="2.6" 
              height="22" 
              open-ended="true" 
              side="double" 
              color="#FFFFFF" 
              opacity="0.1" 
              visible="false"
              rotaiton="-10 0 0"
              material="emissive: rgb(0, 255,0); emissiveIntensity: 1; opacity: 0.5"
              // material="emissive: rgb(242, 245, 166); emissiveIntensity: 1; opacity: 0.7"
              position="0 9 -7">
            </a-cylinder>
            <a-cylinder id="glowEffect"
            animation__opacity="property: opacity;to: 0.4; loop: true; dir: alternate; dur: 800; easing: linear"

              material="color: rgb(242, 245, 166); opacity: 0.1; shader: flat"
              radius="3.5"
              height="21"
              rotaiton="-10 0 0"
              open-ended="true"
              position="0 7 -7"
              
              side="double">
            </a-cylinder>

          </a-entity>
        </a-entity>
  
      
{/* 
      ----------------------
     -------- Body  ----------
      ----------------------
 */}
 {isAR &&
<a-plane
    // geometry="primitive: plane; width: 5; height: 1.5; borderRadius: 0.5; opacity:0.5"
    id="arMessage"
    //  color="#000"
     width='6'
     height='3'
     rotation='0 0 0'
     position="-6 9 -4"
    material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png; transparent:true; alphaTest:0.5;"
  //  text="value:You tryin' to Augment Reality?; color:#00FF00; align:center;
  //  scale: 1.5 1.5 1.5"
 >
  <a-text id="bubble-count" 
    value="Active Bubbles: 0" 
    color="#00FF00"
    align="left"
    position="-2.25 0.5 0.01" 
      scale='1.5 1.5 1.5'></a-text>
      <a-text id="score" value="Score: 0" //position="-6 9 -3"  
        // rotation="0 20 0"
        align="center"
        position="-1 0 0.01"
        scale="2 2 2" color="#00FF00"></a-text>
  <a-text id="speed" 
    value="Rate: Easier" 
    color="#00FF00"
    align="left"
    position="-2.25 -0.75 0.01" 
      scale='1.5 1.5 1.5'></a-text>
</a-plane>}

  {/* <a-entity
    twine-shader
    geometry="primitive: plane; width: 15; height: 20"
    position="0 1.5 -1"
    // scale="10 15 0"
    /> */}

    </a-scene>}

    
{isAR && showTwineStory && 
  <TwineStory 
    isVisible={showTwineStory} 
    setIsGameStart={setIsGameStart}
    isGameStart={isGameStart}
    setIsPaused={setIsPaused} 
    setIsGameEnd={setIsGameEnd}
    showWarning={showWarning} 
    currentPassage={currentPassage} 
    setCurrentPassage={setCurrentPassage} 
    setShowTwineStory={setShowTwineStory} 
    setIsActivated={setIsActivated} 
   isActivated={isActivated} 
   setFrameRef={setFrameRef}
    onStoryComplete={handleStoryComplete} />
 }
 {isGameEnd && <div id='twine-llxr' style={{position:'fixed',top:'50%', right:'25%',
 padding: '0.5rem',
 width:'400px',height:'400px',
 // transform:'translate(50%,0%)',
  backgroundColor:'#00FF00',
  height:'auto',zIndex:9999999}}>Game Over!</div>}
    
  </Container>
}

export default XREnv