import React from 'react'
import { useState, useEffect, useRef } from 'react'

const AJBody = (props) => {
   
    const [ isAR, setIsAR ] = useState(true)
   const [ blink, setBlink ] = useState(false)
   const [ blink2, setBlink2 ] = useState(false)
   const [ shirtNum, setShirtNum ] = useState(0)
    const {setView, view, isActivated } = props
    const face1 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731962627/Portfolio/Self-Portrait-Plain-NoGlasses_oqjs1i.png'
  const face2 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731962628/Portfolio/Self-Portrait-Plain-NoGlasses-Closed_pkjpyh.png'
  const armsAndLegs ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1732134605/ART/Me_ARMS_LEGS_01_croz3v.png'
 //  const gloaun1 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1715795043/PARALLAXR_PRINTFILES/GLOAUN_BASE_BL_01_hvs651.png'
  const gloaun1 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731970728/GLOAUN_001_BL-01-SML_otxefk.png'
  const gloaun2 ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731963838/ART/GLOAUN_Blink_yfac78.png'
  const shirts =['https://res.cloudinary.com/dzxzdsnha/image/upload/v1715089642/Shirt-Gridwalker-Basic-Front-_Fit_usjxel.png',
  'https://res.cloudinary.com/dzxzdsnha/image/upload/v1715090957/Shirt-GloAun-Basic-Front-_Fit_rno7jl.png',
  'https://res.cloudinary.com/dzxzdsnha/image/upload/v1715091820/Shirt-Sage-Basic-Front-Fit_fpd9jl.png']

  const nametag ='https://res.cloudinary.com/dzxzdsnha/image/upload/v1731977669/Hello-My-Name-Is-AJ_Bold_nnltsk.png'
 
 const sleep =(time)=>{
                return new Promise(resolve => {
                    setTimeout(resolve, time)
                    // setAnimationFrame instread for event loop purposes
                })  
            }
  function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const blinkFunc = async (blinkFunc) =>{
        let blinkTimes =  Math.floor(Math.random() * 1) + 2
        blinkFunc(false);
        for( let i =0; i < blinkTimes; i++ ){

            blinkFunc(true);
             await sleep(Math.floor(Math.random() * 1000) + 500)
            blinkFunc(false);
        }
    }
    useEffect(()=>{
      // const changeShirt = () => {
         if( isActivated && shirtNum === 1 ){
          return
        }else{
        sleep(1000)
          const intervalId = setInterval(() => {
                 setShirtNum((shirtNum + 1) % shirts?.length) 
          }, 2000); 
  
          return () => {
              
              clearInterval(intervalId);
          };
      }

      // changeShirt()
    },[shirtNum])

     useEffect(() => {
          const blinkInc = !blink? 1 : getRandomInt(1, 2);
        if(blink){
            sleep(1000)
        }
          const intervalId = setInterval(() => {
               blinkFunc(setBlink) 
        }, Math.floor(Math.random() * 1000) + ( 5000 * blinkInc )); // Random interval between 1 to 6 seconds

        return () => {
            
            clearInterval(intervalId);
        };
    }, []);

     useEffect(() => {
          const blinkInc = !blink2? 1 : getRandomInt(1, 2);
        if(blink2){
            sleep(1000)
        }
          const intervalId = setInterval(() => {
               blinkFunc(setBlink2) 
        }, Math.floor(Math.random() * 1000) + ( 5000 * blinkInc )); // Random interval between 1 to 6 seconds

        return () => {
            
            clearInterval(intervalId);
        };
    }, []);


  return (
    <a-entity>
          <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1690056136/MedMan_jkhqdd.png; transparent:true; color:rgb(255,255,255) ;alphaTest: 0.5 " 
          shadow="cast:true; receive:true"
          height="2" width="2" position="4.5 7.5 -4"  rotation="0 -20 0" /> 

          <a-image
            // src="#groundTexture" 
            height="15" width="10" position='0 3 -6.2'
            // animation="property: rotation; to: -90 -360 0; loop: true; dur: 18000; easing: linear"
            rotation="2 0 0" 
            // rotation="-15 -10 -10" 
            opacity="1"
            //  metalness='0.6' roughness=' 0.4'
            
            material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732056749/ART/Porthole_Plain_kjw7mw.png; transparent:true; alphaTest:0.5;" 
            // shadow="cast:true; receive:true"
            // material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg; transparent:true; alphaTest:0.5;" shadow="cast:true; receive:true"
                // onClick={()=>{
                //         setView('home')
                //     }}
                // onPress={()=>{
                //         setView('home')
                //     }}
            />
     <a-image
      // src="#groundTexture" 
      height="15" width="10" position='0.5 -2.4 -4'
      // animation="property: rotation; to: -90 -360 0; loop: true; dur: 18000; easing: linear"
      rotation="-75 10 0" 
      // rotation="-15 -10 -10" 
      opacity="1"
      //  metalness='0.6' roughness=' 0.4'
      
      material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732056749/ART/Porthole_Plain_kjw7mw.png; transparent:true; alphaTest:0.5;" 
    //   shadow="cast:true; receive:true"
      // material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg; transparent:true; alphaTest:0.5;" shadow="cast:true; receive:true"

     />
     {/* <a-entity  position="-0.5 1 1" >
        <a-entity light="type: ambient; color: #BBB"></a-entity>
        <a-entity light="type: directional; color: #FFF; intensity: 0.6" 
        animation="property: rotation; to: 360 0 0; dur: 5000; easing:linear;loop: true;"
        // animation="property: intensity; to: 1; dur: 500; easing:linear;loop: true;"
        ></a-entity>
     </a-entity> */}
        <a-plane material={`src:${!blink? face1:face2}; transparent:true; color:rgb(255,255,255); alphaTest: 0.5; side: double`} //shadow="cast:true; receive:true"
           height="3" width="2.5" position="0 7.5 -5.9"  rotation="0 0 0" 
       render-order="1"
    //    onClick={()=>{
    //                setView('home')
    //            }}
    //    onPress={()=>{
    //                setView('home')
    //            }}
                     
                     />
     
           <a-plane material={`src:${shirts[shirtNum]}; transparent:true; color:rgb(255,255,255); side: double`} 
           // shadow="cast:true; receive:true"
           height="7" width="6.5" position="0.1 3.25 -6"  rotation="0 0 0"
        //    onClick={()=>{
        //            setView('home')
        //        }}
        //    onPress={()=>{
        //                setView('home')
        //            }}
                         
           />
           
           <a-image material={`src:${armsAndLegs}; transparent:true; color:rgb(255,255,255); `} 
           // shadow="cast:true; receive:true"
           height="6.5" width="4.9" position="0.05 0.6 -5.9"  rotation="0 0 0"/>
     
           <a-plane material={`src:${nametag}; transparent:true; color:rgb(255,255,255) `} 
           // shadow="cast:true; receive:true"
           onClick={()=>{
                   // setView('home')
               }}
       onPress={()=>{
                   // setView('home')
               }}
                     
           height=".5" width=".75" position="1.01 5.3 -5.5"  rotation="0 0 0"/>
    </a-entity>
    
  )
}

export default AJBody