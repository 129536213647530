import React from 'react'
import { useState, useEffect, useRef } from 'react'
import * as THREE from 'three';
import AJBody from './AJBody';

const AFRAME = window.AFRAME;




// AFRAME.registerComponent('ar-activator', {
//   init: function() {
//     // var button = document.querySelector('#ar-cube');
//     var scene = this.el;
    
//     // Initially disable AR
//     // scene.setAttribute('arjs', 'sourceType: none;');
//     scene.setAttribute('awsd-controls', 'acceleration:100');
//      scene.setAttribute('arjs', 'trackingMethod: best; sourceType: webcam;debugUIEnabled: false;');
//       scene.setAttribute('XRMode', 'ar');
    
//     // button.addEventListener('click', function() {
//     //   // Enable AR and start the camera
//     //   scene.setAttribute('arjs', 'trackingMethod: best; sourceType: webcam;debugUIEnabled: false;');
//     //   scene.setAttribute('XRMode', 'ar');
//     //   scene.setAttribute('ball-manager');
      
//     //   // button.setAttribute('visible', 'false');
//     // });
//   }
// });

// AFRAME.registerComponent('cursor-listener', {
//   init: function () {
//     var lastIndex = -1;
//     var COLORS = ['red', 'green', 'blue'];
//     this.el.addEventListener('click', function (evt) {

//       lastIndex = (lastIndex + 1) % COLORS.length;
//       this.setAttribute('material', 'color', COLORS[lastIndex]);
//       console.log('I was clicked at: ', evt.detail.intersection.point);
//     });
//   }
// });

const ARTest = (props) => {
    const cubeRef = useRef(null);
     const startARSession =()=> {
      navigator.xr.requestSession('immersive-ar')
        .then((session) => {
          // Successfully started an AR session
        //   setIsSession(true)
          onSessionStarted(session);
        })
        .catch((error) => {
          console.error("Failed to start AR session:", error);
          // handleNoARSupport();
        //   setNonCompat(true)
        //   setIsSession(false)
        });
      }

     
    function onSessionStarted(session) {
        // Setup your XR session here
        
      console.log("AR session started");
      const scene = document.querySelector('#scene');
        if (scene) {
          // Remove AR-specific components
          scene.setAttribute('vr-mode-ui',"enabled: false;");
          scene.setAttribute('arjs',`${"trackingMethod: best; sourceType: webcam; debugUIEnabled: false;"}`);
          // scene.removeAttribute('embedded');
          
          // Hide AR entities
          const arEntities = scene.querySelectorAll('#camera');
          arEntities.forEach(entity => {
            entity.setAttribute('visible', false);
          });
        }
    }

  return (
    <>
    <a-scene 
    //  embedded 
  
      id='scene'
      look-controls-enabled
      awsd-controls-enabled
      //="acceleration:100"
      //  xr-mode-ui={`enabled: ${isAR?'true':'false'}; XRMode: ${isAR?'ar':''};`}
    //   fly='true'
      // vr-mode-ui="enabled: false;"
      // arjs={isAR?"trackingMethod: best; sourceType: none; debugUIEnabled: false;":' sourceType: none;'}
      // arjs={isAR?"trackingMethod: best; sourceType: webcam; debugUIEnabled: false;":'sourceType: none;'}
      renderer="logarithmicDepthBuffer: true;"
      
      //fog="type: linear; color: #AAA"
      //  xr-mode-ui="enabled: true"
      //  xr-mode-ui={`enabled:  ${isAR?'true':'false'}; enterARButton: #cube; XRMode: ${isAR?'ar':'ar'};`}
      XRMode={'ar'}
    //   ar-activator
      >

       <a-assets>
          <img id="groundTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg"/>
          {/* <img id="groundTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731974094/ART/TestGrid_q5jtig.png"/> */}
          {/* <img id="skyTexture" src="https://cdn.aframe.io/a-painter/images/sky.jpg"/> */}
          <img id="grid" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg" crossOrigin="anonymous"></img>
          <img id="porthole" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1732056749/ART/Porthole_Plain_kjw7mw.png"/>
          <img id="skyTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1690040515/Rex_Entropy_Hero_Star_Bg_SML_btvj6p.png"/>
          {/* <img id="skyTexture" crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1690071590/Origins_Expanded_Resize_Lo_mpoxfh.jpg"/> */}
          <img id="rexLogo"crossOrigin="anonymous"  src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731961690/Rex_Entropy_Logo_Glow_rtguvp.png"/>
          <img id="rexIcon"crossOrigin="anonymous" src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1686810148/Rex_Entropy_Icon_Glow_hzsixx.png"/>
        <a-asset-item id="customFont" src={"./assets/Gilroy-FREE/Gilroy-ExtraBold.otf"}></a-asset-item>
        </a-assets>

        {/* <a-entity id="rig"  position="0 0 0 " > */}

        <a-camera 
        position={`0 1.6 -4`}  look-controls
        // look-controls-enabled
        wasd-controls-enabled="acceleration:100"
      // wasd-controls
        id='camera'
          animation__rotation="property: rotation; from: 3 ; to: 0; loop:false; dur:1500; easing: linear;
          "
          animation__position="property: position; to: 0 5 20; loop: false; dur: 1500; easing: linear"
        //  gps-camera rotation-reader
         >
         <a-entity 
         cursor="fuse: false; fuseTimeout: 1500" 
         position="0 0 -15"
        //  opacity="0.5"
        //  cursor-listener
         geometry="primitive: ring; radiusInner: 0.4; radiusOuter: 0.5" 
         material="color: #00FF00; shader: phong; opacity: 0.8"
       animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
          // animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
          animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
         //  raycaster="showLine: true; far: 20; interval: 1000;  opacity: 0.5"
          ></a-entity>
           <a-animation attribute="position"
            dur="2000"
            easing="linear"
            to="0 5 15"></a-animation>
        </a-camera>

        <a-marker type="pattern" url="/pattern-QauRaQR_Framed_Brand-Tag.patt">
        {/* <a-marker type="pattern" url="https://res.cloudinary.com/dzxzdsnha/raw/upload/v1733009384/Patterns/pattern-Shirt_MedMan-Porthole_BASE_ggd4am.patt"> */}
        {/* </a-entity> */}

        {/* <a-light type="ambient" color="#445451"/> */}
        {/* <a-light type="ambient" intensity="1" position="-3 0 1"/> */}

    {/* <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731974094/ART/TestGrid_q5jtig.png" 
     rotation="-90 0 0" height="100" width="100" position="0 0 0"/> */}
     {/* <a-plane src="https://res.cloudinary.com/dzxzdsnha/image/upload/v1731988899/neonGrid_01_hcr19l.jpg" position="0 0 0"
     rotation="-90 0 0" height="5" width="5"/> */}

   
   


    


 {/* <a-entity  position={"4.5 6 -22"}>
  <a-plane
    // geometry="primitive: plane; width: 5; height: 1.5; borderRadius: 0.5; opacity:0.5"
    id="arMessage"
    //  color="#000"
     width='5'
     height='3'
     rotation='0 -20 0'
    material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png; transparent:true; alphaTest:0.5;"
  //  text="value:You tryin' to Augment Reality?; color:#00FF00; align:center;
  //  scale: 1.5 1.5 1.5"
 />
    
      <a-text
        value="You tryin' to"
        align="center"
        color="#00FF00"
     scale="2 2 2"
        position="0 0.25 0.01"
         rotation='0 -20 0'
      ></a-text>
  
      <a-text
        value="Augment Reality?"
        align="center"
        color="#00FF00"
        scale="2 2 2"
        position="0 -0.25 0.01"
         rotation='0 -20 0'
      ></a-text>
      <a-text
        value="|"
        align="center"
        color="#00FF00"
        scale="5 2 2"
        position="1.55 -0.25 1"
         rotation='0 -20 0'
         opacity='0'
          animation="property: opacity; to: 1; dur: 500; easing:linear;loop: true;"
      ></a-text>
  
</a-entity> */}


           

    
      <a-entity position={'0 0 0'} rotation={'-90 0 0'}  >

      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731961690/Rex_Entropy_Logo_Glow_rtguvp.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="6" width="12" position="0 17 -6" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082371/ART/Sun_01_aogss2.png; transparent:true; color:#FFF;alphaTest: 0.5 "
       shadow="cast:true; receive:true"
      height="6" width="6" position="-2 21 -7" opacity='0.4' 
      rotation='75 0 0' 
      animation__opacity="property: opacity;to: 0.7; loop: false; dir: alternate; dur: 15000; easing: linear"
      // animation__position="property: position; to: -2 20 -7; dir: alternate; loop: true; dur: 15000; easing: linear"
      />

      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="10" position="3 20 -7.5" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="15" position="-3 20 -7" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="10" position="-5 15 -5" />
      <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082380/ART/Cloud_01_xyiaon.png; transparent:true; color:#FFF;alphaTest: 0.5;side: double "// shadow="cast:true; receive:true"
      height="10" width="12" position="6 15 -5" rotation="0 -40 0"/>
        {/* <a-plane material={`src:${'https://res.cloudinary.com/dzxzdsnha/image/upload/v1686810148/Rex_Entropy_Icon_Glow_hzsixx.png'}; transparent:true; color:#FFF`}// shadow="cast:true; receive:true"
        height="4" width="4" position="0 6.5 -7"  rotation="0 0 -50"/> */}

        <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732143363/ART/QauRa_Logo_GRN_quodlo.png; transparent:true; color#FFF;side: double" 
        // shadow="cast:true; receive:true"
          height="1.5"
          width="4"
           position="-4 12 -6"
           opacity="1"
           animation__opacity="property: opacity;to: 0.5; loop: true; dir: alternate; dur: 1500; easing: linear"
          />
        
       
        <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1731981953/Parallaxr_Flat_Tag_Green_bxws9j.svg; transparent:true; color#FFF; alphaTest: 0.5; side: double" shadow="cast:true; receive:true"
        height="2" width="2" position="0 12 -6"
        opacity="1"
        animation__opacity="property: opacity;to: 0.5; loop: true; dir: alternate; dur: 1500; easing: linear"
         />


        <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732155657/ART/RexPlayer_Logo_GRN_gdkto8.png; transparent:true; color#FFF; alphaTest: 0.5; side: double" 
        // shadow="cast:true; receive:true"
          height="2"
          width="4"
           position="4 12 -6"
           opacity="1"
           animation__opacity="property: opacity;to: 0.5; loop: true; dir: alternate; dur: 1500; easing: linear"
          
          />

      </a-entity>
      
      <a-text value="Look up," 
          position="0 -0.5 10" 
          rotation="-45 0 0"
          scale="3 3 3"
          color="red" 
          align="center" 
          show-when-looking-down="threshold: -40">
      </a-text>
      <a-text value="life's beautiful." 
          position="0 -1.5 10" 
          scale="3 3 3"
          rotation="-45 0 0"
          color="red" 
          align="center" 
          show-when-looking-down="threshold: -40">
      </a-text>
   
{/* 
      ----------------------
     -------- Body  ----------
      ----------------------
 */}

        <a-entity position={'0 0 0'}
        rotation={'-90 0 0'} 
        // animation={"property: position; to: 0 -2.5 -20; loop: false; dur: 500; easing: linear"}
         >
             <a-entity  position="-4.5 6 0">
  <a-plane
    // geometry="primitive: plane; width: 5; height: 1.5; borderRadius: 0.5; opacity:0.5"
    id="arMessage"
    //  color="#000"
     width='5'
     height='3'
     rotation='0 20 0'
    material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732156672/ART/TinCanTerm_Glow_GRN_qxjxmi.png; transparent:true; alphaTest:0.5;"
  //  text="value:You tryin' to Augment Reality?; color:#00FF00; align:center;
  //  scale: 1.5 1.5 1.5"
 />
    
      <a-text
        value="You tryin'"
        align="center"
        color="#00FF00"
     scale="2 2 2"
        position="0 0.25 0.01"
         rotation='0 20 0'
      ></a-text>
  
      <a-text
        value="to find out?"
        align="center"
        color="#00FF00"
        scale="2 2 2"
        position="0 -0.25 0.01"
         rotation='0 20 0'
      >
         <a-text
        value="|"
        align="center"
        color="#00FF00"
        scale="5 1 2"
        position="0.65 0 0"
        rotation='0 20 0.1'
        opacity='0'
        animation="property: opacity; to: 1; dur: 1000; easing:linear;loop: true;"
      ></a-text>
      </a-text>

     
      
      {/* <a-image
        src='https://res.cloudinary.com/dzxzdsnha/image/upload/v1732215202/ART/Arrow_Broken_GRN_jq5xik.png'
        align="center"
        color="#00FF00"
        scale="0.6 0.6 0.6"
        position="0 -0.6 0.1"
         rotation='0 20 0'
      ></a-image> */}
  
</a-entity>

      <a-box scene-deactivator
        id='homeCube' ref={cubeRef} position={"-4 4 .05"} rotation="0 0 0" color="#4CC3D9"
        opacity='0.7'
        //  event-set__click="_event: click;_target:#cube; color: #8FF7FF"
        //  event-set__down="_event: mousedown;_target:#cube; color: #8FF7FF"
        //  event-set__up="_event: mouseup;_target:#cube; color: #4CC3D9"
        animation="property: rotation; to: 0 0 360; loop: true; dur: 2000; easing: linear"
        //  animation__scale="property: scale; dir: alternate; dur: 100; loop: true; to: 1.1 1.1 1.1"
        // animation={{property: 'rotation', dur: 2000, loop: true, to: '360 360 360'}}
        onClick={()=>{
            // setView('home')
            //        setIsAR(false)  
                window.location.assign('/')
                }}
                onPress={()=>{
                //     setView('home')
                //   setIsAR(false)  
                
                window.location.assign('/')
            }}
        >
        
       </a-box>
          <AJBody view="small-ar"/>
          {/* <a-box scene-deactivator
       id='cube' position={"0 0 0 "} rotation="0 0 0" color="#4CC3D9"
       opacity='0.7'/> */}
        </a-entity>
        </a-marker>
  
      
{/* 
      ----------------------
     -------- Body  ----------
      ----------------------
 */}
 
    </a-scene>
    </>
  )
}

export default ARTest