import logo from './logo.svg';
import './App.css';
import React from 'react'
import { Route, Routes, BrowserRouter , useParams,useLocation} from 'react-router-dom';
import { useState, useEffect } from 'react'
import InfoButton from './components/InfoBotton/InfoButton';
import Home from './components/Home';
import { Box } from '@mui/material';
import XREnv from './components/XREnv';
import ARTest from './components/ARTest';


function App() {
  const [ view, setView ] = useState('home')

  return (
    <div className="App" style={{margin:'auto',position:'absolute',maxWidth:'100vw',
    maxHeight:'100vh', height:'100vh', backgroundImage: `url("/assets/BodyBG2.png")`,
    backgroundSize: "cover",backgroundRepeat:'no-repeat',fontFamily:'Gilroy',overflow:'hidden'}}>
 <Routes>
          {view === 'home' && <Route path ="/" element ={<Home setView={setView}/>}/> }
          {view === 'xr' &&  <Route path ="/" element ={<XREnv setView={setView} view='xr' id='XREnv'/>}/> }
           <Route path ="/game" element ={
              <XREnv setView={setView} view='xr' id='XREnv'/>
              }/> 
           <Route path ="/ar" element ={<ARTest setView={setView} view='ar' id='ARTest'/> }/>  

  </Routes>
    {/* {view ==='home' && <Home setView={setView}/>}
    {view === 'xr' && <XREnv setView={setView} view='xr' id='XREnv'/>}
     */}
    {/* <Box component={'img'} 
    // src='./assets/Origins_Expanded_Resize.jpg'
    // src='./assets/Origins-(Expanded)_002(BLK_MID).jpg'
    sx={{ position:'absolute', top:0, left:0, zIndex:-1, height:'100%',maxWidth:'100vw', width:'100%',
            backgroundAttachment:'fixed'}}
    >

    </Box> */}
    </div>
  );
}

 export default App;
