import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { XR, createXRStore } from '@react-three/xr'
import EggIcon from '@mui/icons-material/Egg';
// import gilroyFont from './assets/Gilroy-FREE/Gilroy-ExtraBold.otf';

// import 'aframe';
// import AFRAME from 'aframe';
const AFRAME = window.AFRAME;

AFRAME.registerComponent('unfold-box', {
init: function () {
    var foldedBox = document.querySelector('#foldedBox');
    var unfoldedBox = document.querySelector('#unfoldedBox');
    
    this.el.addEventListener('click', function () {
      foldedBox.emit('unfold');
      setTimeout(function() {
        foldedBox.setAttribute('visible', 'false');
        unfoldedBox.setAttribute('scale', '1 1 1');
        unfoldPlanes();
      }, 1000);
    });
    
    function unfoldPlanes() {
      var planes = unfoldedBox.children;
      for (var i = 0; i < planes.length; i++) {
        var plane = planes[i];
        var animationAttr = {
          property: 'rotation',
          dur: 1000,
        //   easing: 'ease-out'
        };
        
        switch(plane.id) {
          case 'front':
            animationAttr.to = '0 0 0';
            break;
          case 'back':
            animationAttr.to = '0 180 0';
            break;
          case 'left':
            animationAttr.to = '0 -90 -90';
            break;
          case 'right':
            animationAttr.to = '0 90 90';
            break;
          case 'top':
            animationAttr.to = '-180 0 0';
            break;
          case 'bottom':
            animationAttr.to = '180 0 0';
            break;
        }
        
        plane.setAttribute('animation', animationAttr);
      }
    }
  }
});

const XROpeningBox = (props) => {
    const {isAR } = props
  return (
    <a-entity>
        <a-entity id="unfoldedBox" position="0 1.5 -3" scale="0 0 0"  animation="property: rotation; to: 360 360 360; loop: true; dur: 2000; easing: linear">
        <a-plane id="front" position="0 0 0.5" width="1" height="1" color="#4CC3D9"></a-plane>
        <a-plane id="back" position="0 0 -0.5" rotation="0 180 0" width="1" height="1" color="#4CC3D9"></a-plane>
        <a-plane id="left" position="-0.5 0 0" rotation="0 -90 0" width="1" height="1" color="#4CC3D9"></a-plane>
        <a-plane id="right" position="0.5 0 0" rotation="0 90 0" width="1" height="1" color="#4CC3D9"></a-plane>
        <a-plane id="top" position="0 0.5 0" rotation="-90 0 0" width="1" height="1" color="#4CC3D9"></a-plane>
        <a-plane id="bottom" position="0 -0.5 0" rotation="90 0 0" width="1" height="1" color="#4CC3D9"></a-plane>
          <a-plane material="src:https://res.cloudinary.com/dzxzdsnha/image/upload/v1732082371/ART/Sun_01_aogss2.png; transparent:true; color:#FFF;alphaTest: 0.5 " shadow="cast:true; receive:true"
              height="2" width="2" //position={isAR?"4 4 -23":"4 4 -3"} 
              opacity='1' 
              // opacity='0.4' 
              rotation='0 0 0' 
              // animation__opacity="property: opacity;to: 0.7; loop: false; dir: alternate; dur: 15000; easing: linear"
              // animation__position="property: position; to: -2 20 -7; dir: alternate; loop: true; dur: 15000; easing: linear"
              />
                <a-entity animation__scale={{property: 'scale', dir: 'alternate', dur: 100, loop: true, to: '2 2 2'}}
               geometry={{primitive: 'sphere', depth: 0.2, height: 0.2, width: 0.2}}
               material={{color: '#24CAFF'}}/>
        </a-entity>
        
       <a-box id="foldedBox" position="0 1.5 -3" color="red" opacity="0.5"
       animation__unfold="property: scale; to: 0 0 0; dur: 1000; startEvents: unfold"
       unfold-box>
    </a-box>
    </a-entity>
  )
}

export default XROpeningBox