import React,{useEffect, useState, useRef, useContext} from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import '../styles.css';

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules';
import { Box, Container, Typography } from '@mui/material';

import { AppContext } from '../context/AppProvider';
const BrowserPojects = (props) => {
 
    const p = props.project
    const {isFullView, setIsFullView ,fullSrc, setFullSrc ,setImgIndex} = useContext(AppContext)

const generatedSlides = () =>{
    let slides = []

    for(let i =0; i < p?.pics.length ; i++){
        // slides?.push(<ProjectCard key={i} index={i}/>)
        slides?.push(
            <SwiperSlide key={i} sx={{padding:'0.5rem',display:'flex'}}>
                <div style={{display:'flex',color:"#fff", cursor:'pointer',gap:'0.5rem', width:'auto',maxHeight:'320px'}} skey={i}>
                    <div style={{display:'flex',borderRadius:'10px',overflow:'hidden',width:'auto',height:'height',
                    // padding:'0.5rem',
                    alignItems:'flex-start',
                    border:'2px solid #fff', boxShadow:'0px 0px 10px 5px rgba(255,255,255,0.5)'
                    }}>
                    <img src={p.pics[i]} style={{backgroundColor:'#FFF'}} onClick={(e)=>{
                      console.log(e.target.src)
                      // setFullSrc(e.target.src.toString())
                      setFullSrc(p.pics)
                      setImgIndex(i)
                      setIsFullView(!isFullView)
                        
                    }}/>
                    </div>
                    <div>
                       {/* { p?.name} */}
                    </div>   
                </div>
        </SwiperSlide>
        )
    }
    // console.log('generatedSlides:',slides)
    return slides
}
 const [swiperInstance, setSwiperInstance] = useState(null);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance?.slideTo(0,300);
    }
  }, [p, swiperInstance]);

useEffect(()=>{

},[fullSrc, isFullView])
//   console.log("----BrowserProjects----:",p)
  return (
        
    <Box sx={{ position: 'absolute',top:'-24.5%', left:'-27%',height:'fit-content', width:'550px', 
        margin:'auto',transform:'scale(0.67)' }}>
   {p?.pics?.length > 0 && <Swiper
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      //  sx={{height:'100%'}}
      >
        {/* <div style={{height:'100%'}}>&nbsp;</div> */}
        {p?.pics?.length > 0 && generatedSlides()?.map((s,i)=>{return s})}
        {/* {p?.pics?.length === 0 && generatedSlides()?.map((s,i)=>{return s})} */}


       

      </Swiper>}
     {/* {p?.pics?.length > 0  && <Box sx={{position: 'absolute',bottom:'-20px',left:'50%',transform:'translate(-50%,0)',backgroundColor:'rgba(255,255,255,0.3)',
          backdropFilter:'blur(5px)', height:'2rem', width:'70%',borderRadius:'5px',zIndex:99999, boxShadow:'0px 0px 10px 5px rgba( 0, 0, 0, 0.5)'}}>
        &nbsp;
       </Box>} */}
     </Box>
    // </Box>
  )
}

export default BrowserPojects