import { Box, Container } from '@mui/material';
import React, { useEffect, useRef,useState } from 'react';

const TwineStory = ({ isVisible,setShowTwineStory, showWarning,
  onStoryComplete, setIsPaused,setIsGameStart ,isGameStart, setIsActivated,
  isActivated, setFrameRef,setCurrentPassage,
  currentPassage, setIsGameEnd }) => {
  const iframeRef = useRef(null);
  
    
  setFrameRef(iframeRef)
    const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    if (isVisible && iframeRef.current) {
      navigateToPassage(currentPassage);
      iframeRef.current.src = `/assets/RexEntropyGameIntro.html#${currentPassage}`;
      console.log('[iframeRef.current.src ]:,', iframeRef.current.src );
      setIframeLoaded(true);
    }
    
  }, [iframeLoaded,isVisible,currentPassage]);

  useEffect(() => {
    if (iframeLoaded && iframeRef.current) {
      iframeRef.current.onload = () => {
        console.log('Iframe loaded, sending navigation message');
        setTimeout(async() => {
         await navigateToPassage(currentPassage);
        }, 0);
      };
    }
  }, [iframeRef.current,iframeLoaded, currentPassage]);

  
  // useEffect(() => {

  //         navigateToPassage(currentPassage);

  // }, [currentPassage]);

  

  const navigateToPassage = async(passageName) => {
    try{
      
      if (iframeRef.current && iframeRef.current.contentWindow) {
        console.log('Sending message to navigate to:', passageName);
        // console.log('Sending message [content window]:', iframeRef.current.contentWindow);
        iframeRef.current.contentWindow.postMessage({
         type: 'navigateToPassage',
         passageName: passageName
       }, '*');
    }
  }catch(err){console.log(err)}
};


  // useEffect(() => {
  //   try{

  //     const iframeWindow = iframeRef?.current?.contentWindow;
  //       //if( !iframeWindow?.State?.variables?.hideStory ){
  //       // iframeWindow.State.variables.hideStory = false;
  //     //   console.log('[Twine Story] hideStory:', iframeWindow?.State?.variables?.hideStory)
  //     // }
  //       // if (isVisible ) {
  //       if (isVisible && iframeRef.current) {
  //           // Load the Twine story
  //             // iframeRef.current.src = `/assets/RexEntropyGameIntro.html#SystemWarning`;
            
  //             iframeRef.current.src = `/assets/RexEntropyGameIntro.html`;
  //           console.log('[Twine Story] src:', iframeRef.current.src)
  //           console.log('[Twine Story] currentPassage:', currentPassage)
  //            if (iframeRef.current) {
  //           setTimeout(() => {
  //           iframeRef.current.onload = () => {
  //             navigateToPassage(currentPassage);
  //           }
  //         },500);
  //       }

  //     }
  //   }catch(err){console.log(err)}
  // }, [ currentPassage]);

//   useEffect(() => {
//   if (iframeRef.current) {
//     iframeRef.current.onload = () => {
//     // if(currentPassage !=='Intro'){
//       navigateToPassage(currentPassage);
//     }
//     };
//   // }
// }, []);

//----Listen to messages from Twine
  window.addEventListener('message', function(event) {

  if (event.data === 'twine-story-complete') {
    // Handle the completion of the Twine story
    // For example, hide the Twine HUD and resume gameplay
    setShowTwineStory(false);
    setIsPaused(false);
  }
  if (event.data === 'hide-story') {
    // Handle the completion of the Twine story
    // For example, hide the Twine HUD and resume gameplay
     console.log('[Twine Story] hide-story:', event.data)
    setShowTwineStory(false);
    setIsPaused(false);
  }
  if (event.data === 'show-story') {
    // Handle the completion of the Twine story
    // For example, hide the Twine HUD and resume gameplay
    //  console.log('[Twine Story] show-story:', event.data)
    setShowTwineStory(true);
    setIsPaused(true);
  }
  if (event.data === 'close-game') {
    // Handle the completion of the Twine story
    // For example, hide the Twine HUD and resume gameplay
    //  console.log('[Twine Story] show-story:', event.data)
    setShowTwineStory(false);
    setIsPaused(true);
    setIsGameEnd(true)
    window.close()
  }
  if (event.data === 'end-game') {
    // Handle the completion of the Twine story
    // For example, hide the Twine HUD and resume gameplay
    //  console.log('[Twine Story] show-story:', event.data)
    setShowTwineStory(false);
    setIsPaused(true);
    setIsGameEnd(true)
    
  }
  if (event.data === 'basic-ending') {
    try{

      setCurrentPassage('BasicConclusion')
       const event = new CustomEvent('BasicConclusion', { detail: { isActivated: false } });
       document.dispatchEvent(event);
       console.log('----------[basic-ending listener]---------')
      //  setShowTwineStory(false)
      //  setIsPaused(false)
    }catch(err){console.log('[basic-ending listener]', err)}
       
    
  }
  if (event.data === 'activate') {
    // Handle the completion of the Twine story
    // For example, hide the Twine HUD and resume gameplay
    //  console.log('[Twine Story] show-story:', event.data)
    //  console.log('[Twine Story] show-story:', event.data)
    // setCurrentPassage(true);
    
    setShowTwineStory(false);
    setIsPaused(false);
  }
});

  useEffect(() => {

  const handleHideStory = () => {
    const iframeWindow = iframeRef.current.contentWindow;
    if (iframeWindow && iframeWindow.State.variables.hideStory) {
      setShowTwineStory(true);
      setIsPaused(false);
      if( !isGameStart ){
        setIsGameStart(true)
      }
    }
    if (iframeWindow && !iframeWindow.State.variables.hideStory) {
      setShowTwineStory(true);
      setIsPaused(true);
      if( !isGameStart ){
        setIsGameStart(true)
      }
    }
  };

  if (iframeRef.current) {
    iframeRef.current.addEventListener('load', () => {
      iframeRef.current.contentWindow.addEventListener('hide-story', handleHideStory);
      iframeRef.current.contentWindow.addEventListener('show-story', handleHideStory);
    });
  }

  return () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.removeEventListener('hide-story', handleHideStory);
      iframeRef.current.contentWindow.removeEventListener('show-story', handleHideStory);
    }
  };
}, [isVisible]);

  return (
  <Container id='twine-story'
    style={{width: '100%', height: '100%', position: 'fixed', left: 0, top: '10%', zIndex: 9, overflow: 'hidden'}}
  >
 <div id='twine-llxr' style={{position:'fixed',top:'10%', right:'10%',width:'100px',height:'auto',zIndex:9999999}}><img src="/assets/MedMan_Flat.png" width='100%' height='auto'/></div>
      <iframe 
        ref={iframeRef}
        style={{ width: '100%', height: '100%', border: 'none' }}
        onLoad={() => {
          // Add event listener to detect when the story is complete
          iframeRef.current.contentWindow.addEventListener('twine-story-complete', onStoryComplete);
         iframeRef.current.contentWindow.addEventListener('hide-story', ()=>{
          setShowTwineStory(false)
         });
      iframeRef.current.contentWindow.addEventListener('show-story', ()=>{
          console.log('------Twine story event test--------')
          setShowTwineStory(true)

         });
        }}
    />
  </Container>
   
    
   
  
  );
};
export default TwineStory 